var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.payload),
      "spinner-variant": 'success'
    }
  }, [_c('div', {
    staticClass: "py-1"
  }, [_vm.payload ? [_c('b-card', {
    staticClass: "mb-0 border rounded-lg",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "px-75 py-50",
      "body-class": "p-50 p-md-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trainIcon",
            "size": "22"
          }
        }), _c('div', {
          class: "fw-700 text-nowrap\n                ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-3', "\n              ")
        }, [_vm._v(" Xác nhận thông tin mua vé ")])], 1)];
      },
      proxy: true
    }], null, false, 1821384933)
  }, [_c('div', {
    staticClass: "fw-800 mt-75"
  }, [_vm._v(" Thông tin hành khách: ")]), _vm._l(_vm.getTicketList(_vm.payload.passengers), function (ticketValues, key) {
    return _c('b-row', {
      key: key,
      staticClass: "border my-25 rounded",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      staticClass: "my-auto p-50",
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('div', [_vm._v(" Đối tượng: "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s(_vm.getNamePaxType(ticketValues[0].passengerTargetCode)))])]), _c('div', [_vm._v(" Tên hành khách: "), _c('span', {
      staticClass: "fw-700 text-18px"
    }, [_vm._v(_vm._s(ticketValues[0].fullName))])]), _c('div', [_vm._v(" Số giấy tờ: "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s(ticketValues[0].identifyNumber))])])]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "9"
      }
    }, [_c('b-table-lite', {
      staticClass: "mb-0",
      attrs: {
        "bordered": "",
        "responsive": "",
        "small": "",
        "thead-class": "text-body fw-600 text-nowrap text-center",
        "items": ticketValues,
        "fields": _vm.tablePaxPriceColumns
      },
      scopedSlots: _vm._u([_vm._l(_vm.tablePaxPriceColumns, function (column) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: column,
              staticClass: "text-body"
            }, [_vm._v(" " + _vm._s(_vm.$t("train.search.result.columns.".concat(data.column))) + " ")])];
          }
        };
      }), {
        key: "cell(seatInfo)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            staticClass: "text-nowrap fw-700",
            staticStyle: {
              "min-width": "155px"
            }
          }, [_vm._v(" " + _vm._s(item.trainCode) + " " + _vm._s("".concat(_vm.getAirportName(item.departure), "-").concat(_vm.getAirportName(item.arrival))) + " ")]), _c('div', {
            staticClass: "text-body"
          }, [_c('div', {
            staticClass: "text-nowrap"
          }, [_vm._v(" Khởi hành: "), _c('span', {
            staticClass: "fw-600"
          }, [_vm._v(_vm._s(_vm.convertISODateTimeLangVN(item.departureTime).shortDay) + ", " + _vm._s(_vm.convertISODateTime(item.departureTime).time))])]), _c('div', {
            staticClass: "text-nowrap"
          }, [_vm._v(" Đến: "), _c('span', {
            staticClass: "fw-600"
          }, [_vm._v(_vm._s(_vm.convertISODateTimeLangVN(item.arrivalTime).shortDay) + " | " + _vm._s(_vm.convertISODateTime(item.arrivalTime).time))])])]), _c('div', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.cart.coach')) + " "), _c('span', {
            staticClass: "fw-700"
          }, [_vm._v(_vm._s(item.coachName))]), _vm._v(" " + _vm._s(_vm.$t('train.search.result.cart.seat')) + " "), _c('span', {
            staticClass: "fw-700"
          }, [_vm._v(_vm._s(item.seatName))])]), _c('div', {
            class: "".concat(['GP'].includes(item.seatTypeCode))
          }, [_vm._v(" " + _vm._s(_vm.resolveNameByListSeatType(item.seatTypeCode)) + " ")]), item.refTicketId ? _c('div', {
            class: "text-info fw-700\n                    ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', "\n                  ")
          }, [_vm._v(" " + _vm._s(item.isReturn ? _vm.$t('train.search.result.roundTripInbound') : _vm.$t('train.search.result.roundTripOutbound')) + " ")]) : _c('div', {
            class: "text-warning fw-700\n                    ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', "\n                  ")
          }, [_vm._v(" " + _vm._s(item.isReturn ? _vm.$t('train.search.result.oneWayInbound') : _vm.$t('train.search.result.oneWayOutbound')) + " ")])];
        }
      }, {
        key: "cell(ticketPrice)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_c('div', {
            staticClass: "text-right"
          }, [_vm._v(" " + _vm._s(_vm.resolveTotalPriceTicket(item, false)) + " ")])];
        }
      }, {
        key: "cell(addonsDetails)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('div', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])];
        }
      }, {
        key: "cell(discountService)",
        fn: function fn(_ref4) {
          var item = _ref4.item;
          return [!item.applyPromoData ? _c('div', {
            staticClass: "text-right"
          }, [_vm._v(" 0 ")]) : _c('div', {
            staticStyle: {
              "min-width": "170px"
            }
          }, [_c('div', {
            staticClass: "fw-600"
          }, [_vm._v(" " + _vm._s(item.applyPromoData.promotionName) + " (giảm "), _c('span', {
            staticClass: "fw-800"
          }, [_vm._v(_vm._s(_vm.formatCurrency(item.applyPromoData.calcPromotion.commission)))]), _vm._v(") ")]), _c('small', {
            staticClass: "text-danger font-italic"
          }, [_vm._v(" * " + _vm._s(_vm.$t('train.search.result.promoNote')) + " ")])])];
        }
      }, {
        key: "cell(totalPrice)",
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_c('div', {
            staticClass: "text-right text-18px fw-700 text-info"
          }, [_vm._v(" " + _vm._s(_vm.resolveTotalPriceTicket(item, false)) + " ")])];
        }
      }], null, true)
    })], 1)], 1);
  }), _c('div', {
    staticClass: "fw-800 text-success text-right text-nowrap py-25"
  }, [_c('span', {
    staticClass: "font-medium-1 mr-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.totalAmount')) + " ")]), _c('span', {
    staticClass: "font-medium-4 mr-1"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalCartTicketPrice)) + " ")])]), _c('b-row', {
    staticClass: "mt-75"
  }, [_c('b-col', {
    staticClass: "fw-800",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Thông tin liên hệ: ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Tên khách hàng: " + _vm._s(_vm.payload.bookingPerson.fullName) + " ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Số giấy tờ: " + _vm._s(_vm.payload.bookingPerson.identifyNumber) + " ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Email: " + _vm._s(_vm.payload.bookingPerson.email) + " ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Số điện thoại: " + _vm._s(_vm.payload.bookingPerson.phoneNumber) + " ")])], 1)], 2)] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex-between"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.goBack')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "success",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('saveBooking');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.bookTicket')) + " ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }