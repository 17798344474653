<template>
  <IAmOverlay
    :loading="isEmpty(payload)"
    :spinner-variant="'success'"
  >
    <div class="py-1">
      <template v-if="payload">
        <b-card
          header-bg-variant="light-success"
          header-class="px-75 py-50"
          body-class="p-50 p-md-1"
          class="mb-0 border rounded-lg"
        >
          <template #header>
            <div class="d-flex-center justify-content-start gap-1">
              <IAmIcon
                icon="trainIcon"
                size="22"
              />
              <div
                :class="`fw-700 text-nowrap
                  ${isMobileView ? 'font-medium-1' : 'font-medium-3'}
                `"
              >
                Xác nhận thông tin mua vé
              </div>
            </div>
          </template>

          <div class="fw-800 mt-75">
            Thông tin hành khách:
          </div>
          <b-row
            v-for="(ticketValues, key) of getTicketList(payload.passengers)"
            :key="key"
            class="border my-25 rounded"
            no-gutters
          >
            <b-col
              cols="12"
              lg="3"
              class="my-auto p-50"
            >
              <div>
                Đối tượng:
                <span class="fw-700">{{ getNamePaxType(ticketValues[0].passengerTargetCode) }}</span>
              </div>
              <div>
                Tên hành khách:
                <span class="fw-700 text-18px">{{ ticketValues[0].fullName }}</span>
              </div>
              <div>
                Số giấy tờ:
                <span class="fw-700">{{ ticketValues[0].identifyNumber }}</span>
              </div>
            </b-col>
            <b-col
              cols="12"
              lg="9"
            >
              <b-table-lite
                bordered
                responsive
                small
                class="mb-0"
                thead-class="text-body fw-600 text-nowrap text-center"
                :items="ticketValues"
                :fields="tablePaxPriceColumns"
              >
                <template
                  v-for="column in tablePaxPriceColumns"
                  #[`head(${column})`]="data"
                >
                  <span
                    :key="column"
                    class="text-body"
                  >
                    {{ $t(`train.search.result.columns.${data.column}`) }}
                  </span>
                </template>
                <template #cell(seatInfo)="{ item }">
                  <div
                    class="text-nowrap fw-700"
                    style="min-width: 155px"
                  >
                    {{ item.trainCode }} {{ `${getAirportName(item.departure)}-${getAirportName(item.arrival)}` }}
                  </div>
                  <div class="text-body">
                    <div class="text-nowrap">
                      Khởi hành: <span class="fw-600">{{ convertISODateTimeLangVN(item.departureTime).shortDay }}, {{ convertISODateTime(item.departureTime).time }}</span>
                    </div>
                    <!-- <b-img
                          blank-color="#ccc"
                          src="@icons/dot.svg"
                          class="m-50"
                        /> -->
                    <div class="text-nowrap">
                      Đến: <span class="fw-600">{{ convertISODateTimeLangVN(item.arrivalTime).shortDay }} | {{ convertISODateTime(item.arrivalTime).time }}</span>
                    </div>
                  </div>
                  <div class="text-nowrap">
                    {{ $t('train.search.result.cart.coach') }} <span class="fw-700">{{ item.coachName }}</span> {{ $t('train.search.result.cart.seat') }} <span class="fw-700">{{ item.seatName }}</span>
                  </div>
                  <div :class="`${['GP'].includes(item.seatTypeCode)}`">
                    {{ resolveNameByListSeatType(item.seatTypeCode) }}
                  </div>
                  <div
                    v-if="item.refTicketId"
                    :class="`text-info fw-700
                      ${isMobileView ? 'font-small-4' : 'font-medium-2'}
                    `"
                  >
                    {{ item.isReturn ? $t('train.search.result.roundTripInbound') : $t('train.search.result.roundTripOutbound') }}
                  </div>
                  <div
                    v-else
                    :class="`text-warning fw-700
                      ${isMobileView ? 'font-small-4' : 'font-medium-2'}
                    `"
                  >
                    {{ item.isReturn ? $t('train.search.result.oneWayInbound') : $t('train.search.result.oneWayOutbound') }}
                  </div>
                </template>
                <template #cell(ticketPrice)="{ item }">
                  <!-- <div
                    v-b-tooltip.hover.html.window.v-primary
                    class="d-flex-between mb-25"
                    :title="`${$t('train.search.result.ticketPrice')} ${resolvePriceTicket(item).priceWithInsurance}<br>
                        ${$t('train.search.result.serviceFee')} ${resolvePriceTicket(item).serviceFee}<br>
                        `"
                  >
                    <div class="text-nowrap mr-50">
                      {{ $t('train.search.result.ticketPrice') }}
                    </div>
                    <div class="text-right fw-600">
                      {{ resolvePriceTicket(item).resultPrice }}
                    </div>
                  </div>
                  <div class="d-flex-between">
                    <div class="text-nowrap mr-50">
                      {{ $t('train.search.result.discountEligible') }}
                    </div>
                    <div class="text-right fw-600 text-warning">
                      {{
                        item.applyPromoData
                          ? formatCurrency(item.applyPromoData.calcPromotion.discountPassenger)
                          : formatCurrency(resolveDiscountPriceByPassengerCode(item.ticketPrice, item.passengerTargetCode))
                      }}
                    </div>
                  </div> -->

                  <!-- FIXME:  nếu có thêm addons tính lại giá -->
                  <div class="text-right">
                    {{ resolveTotalPriceTicket(item, false) }}
                  </div>
                </template>
                <template #cell(addonsDetails)="{ item }">
                  <div class="text-center">
                    -
                  </div>
                </template>
                <template #cell(discountService)="{ item }">
                  <div
                    v-if="!item.applyPromoData"
                    class="text-right"
                  >
                    0
                  </div>
                  <div
                    v-else
                    style="min-width: 170px"
                  >
                    <div class="fw-600">
                      {{ item.applyPromoData.promotionName }} (giảm <span class="fw-800">{{ formatCurrency(item.applyPromoData.calcPromotion.commission) }}</span>)
                    </div>
                    <small class="text-danger font-italic">
                      * {{ $t('train.search.result.promoNote') }}
                    </small>
                  </div>
                </template>
                <template #cell(totalPrice)="{ item }">
                  <div class="text-right text-18px fw-700 text-info">
                    {{ resolveTotalPriceTicket(item, false) }}
                  </div>
                </template>
              </b-table-lite>
            </b-col>
          </b-row>

          <!-- ANCHOR - Tổng tiền -->
          <div class="fw-800 text-success text-right text-nowrap py-25">
            <span class="font-medium-1 mr-1">
              {{ $t('train.search.result.totalAmount') }}
            </span>
            <span class="font-medium-4 mr-1">
              {{ formatCurrency(getTotalCartTicketPrice) }}
            </span>
          </div>

          <b-row class="mt-75">
            <b-col
              cols="12"
              class="fw-800"
            >
              Thông tin liên hệ:
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              Tên khách hàng:
              {{ payload.bookingPerson.fullName }}
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              Số giấy tờ:
              {{ payload.bookingPerson.identifyNumber }}
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              Email:
              {{ payload.bookingPerson.email }}
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              Số điện thoại:
              {{ payload.bookingPerson.phoneNumber }}
            </b-col>
          </b-row>
        </b-card>
      </template>
    </div>

    <div class="d-flex-between">
      <b-button
        variant="outline-secondary"
        pill
        @click="$emit('back')"
      >
        {{ $t('train.search.result.goBack') }}
      </b-button>
      <b-button
        variant="success"
        pill
        @click="$emit('saveBooking')"
      >
        {{ $t('train.search.result.bookTicket') }}
      </b-button>
    </div>
  </IAmOverlay>
</template>

<script>
import {
  BCard, BTableLite,
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'

import IAmOverlay from '@/components/IAmOverlay.vue'
import { convertISODateTime, convertISODateTimeLangVN, formatCurrency } from '@/@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BTableLite,
    BRow,
    BCol,
    BButton,

    IAmOverlay,
  },
  props: {
    payload: {
      type: [Object, null],
      default: null,
    },
  },
  setup() {
    const {
      // tablePaxPriceColumns,
      getTotalCartTicketPrice,
      getListPassengerTarget,

      getAirportName,
      resolveTotalPriceTicket,
      resolveNameByListSeatType,
      getServiceFeeByPassengerTargetCode,
      resolveDiscountPriceByPassengerCode,
    } = useTrainHandle()

    function getTicketList(tickets) {
      const groupByPax = groupBy(tickets, ticket => `${ticket.fullName}-${ticket.identifyNumber}`)
      return groupByPax
    }

    const tablePaxPriceColumns = ['seatInfo', 'ticketPrice', 'addonsDetails', 'discountService', 'totalPrice']

    function resolvePriceTicket(ticket) {
      const basePrice = ticket.applyPromoData ? (ticket.applyPromoData?.calcPromotion?.basePrice || 0) : (ticket.basePrice || 0)
      const insurancePrice = ticket.applyPromoData ? (ticket.applyPromoData?.calcPromotion?.insurancePrice || 0) : (ticket.insurancePrice || 0)
      const serviceFee = getServiceFeeByPassengerTargetCode(ticket.passengerTargetCode)
      const resultPrice = basePrice + insurancePrice + serviceFee
      return {
        basePrice: formatCurrency(basePrice),
        serviceFee: formatCurrency(serviceFee),
        insurancePrice: formatCurrency(insurancePrice),
        priceWithInsurance: formatCurrency(basePrice + insurancePrice),
        resultPrice: formatCurrency(resultPrice),
      }
    }

    function getNamePaxType(code) {
      const data = getListPassengerTarget.value?.find(p => p?.code === code)
      if (data && data?.code === 6) return 'Người lớn nước ngoài'
      if (data && data?.code === 7) return 'Trẻ em nước ngoài'
      return data?.name || ''
    }

    return {
      tablePaxPriceColumns,
      getTotalCartTicketPrice,

      isEmpty,
      getTicketList,
      convertISODateTime,
      convertISODateTimeLangVN,
      resolveDiscountPriceByPassengerCode,

      formatCurrency,
      resolvePriceTicket,
      getAirportName,
      resolveNameByListSeatType,
      resolveTotalPriceTicket,
      getNamePaxType,
    }
  },
}
</script>
